import { Typography } from "@mui/material";
import React from "react";

const VisionAndMission = () => {
  return (
    <div className="container mx-auto px-4 md:px-0 py-[75px]">
      <div className="grid grid-cols-12 gap-4 md:gap-12">
        <div className="col-span-12 md:col-span-6 text-white flex items-center justify-end">
          <div className="p-8 bg-black rounded-2xl md:max-w-[80%]">
            <Typography variant="h4" fontFamily={"raleway"}>
              Vision
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"300"}
              sx={{ mt: "20px" }}
              fontFamily={"poppins"}
            >
              Envisioning a future of thriving small and medium enterprises,
              empowered by innovative business operations support and
              sustainable cost management.
            </Typography>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 text-white flex items-center justify-start">
          <div className="p-8 bg-black rounded-2xl md:max-w-[80%]">
            <Typography variant="h4" fontFamily={"raleway"}>
              Mission
            </Typography>
            <Typography
              variant="body1"
              fontWeight={"300"}
              sx={{ mt: "20px" }}
              fontFamily={"poppins"}
            >
              Empowering small and medium enterprises to thrive through
              strategic business support and cost reduction.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionAndMission;
