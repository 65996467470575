import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import appLogoColored from "../../../../assets/branding/appLogoColored.svg";
import TikTokIcon from "../../../../assets/illustrations/TikTokIcon";
import { appInternalRoutes } from "../../../../data/constants/appInternalRoutes";
import { vsServicesData } from "../../../../data/constants/vsServicesData";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-black text-white p-8">
      <div className="container mx-auto">
        <div className="grid grid-cols-12 gap-4 lg:gap-24">
          <div className="col-span-12 md:col-span-3 h-full">
            <div className="space-y-2 flex flex-col justify-between h-full">
              <div>
                <img
                  onClick={() => {
                    navigate(appInternalRoutes.HOME_PAGE);
                    window.scrollTo(0, 0);
                  }}
                  src={appLogoColored}
                  className="mx-auto cursor-pointer"
                />
                <div className="flex items-center justify-between my-8 w-11/12 *:cursor-pointer">
                  <TikTokIcon
                    onClick={() => {
                      window.open(
                        "https://www.tiktok.com/@coffeewithkamal?_t=8lMmp6InO4M&_r=1",
                        "_blank"
                      );
                    }}
                  />
                  <FacebookIcon
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/kamal.tomar.792",
                        "_blank"
                      );
                    }}
                  />
                  <InstagramIcon
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/coffeewithkamal/",
                        "_blank"
                      );
                    }}
                  />
                  {/* <XIcon
                    onClick={() => {
                      window.open(
                        "https://www.tiktok.com/@coffeewithkamal?_t=8lMmp6InO4M&_r=1",
                        "_blank"
                      );
                    }}
                  /> */}
                  <LinkedInIcon
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/in/kamaltomar",
                        "_blank"
                      );
                    }}
                  />
                  {/* <YouTubeIcon
                    onClick={() => {
                      window.open(
                        "https://www.tiktok.com/@coffeewithkamal?_t=8lMmp6InO4M&_r=1",
                        "_blank"
                      );
                    }}
                  /> */}
                </div>
              </div>
              <div className="spacey-y-2 text-center md:text-left">
                <Typography variant="h5">
                  unit 700, 5995 Avebury Road, Mississauga
                </Typography>
                <Typography variant="body2">Ontario L5R 3P9 canada</Typography>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3 space-y-8 text-center md:text-left">
            <Typography variant="h5">Services</Typography>

            <div className="space-y-4 *:cursor-pointer">
              {vsServicesData?.map((item, index) => (
                <Typography
                  onClick={() => {
                    navigate(appInternalRoutes.CONTACT_US_PAGE);
                  }}
                  key={index}
                  variant="subtitle2"
                >
                  {item?.title}
                </Typography>
              ))}
            </div>
          </div>
          <div className="col-span-12 md:col-span-3">
            <div className="space-y-8 text-center md:text-left">
              <Typography variant="h5">Quick Links</Typography>

              <div className="space-y-4 *:cursor-pointer">
                <Typography
                  onClick={() => navigate(appInternalRoutes.ABOUT_US_PAGE)}
                  variant="subtitle2"
                >
                  About Us
                </Typography>
                <Typography
                  onClick={() => navigate(appInternalRoutes.CONTACT_US_PAGE)}
                  variant="subtitle2"
                >
                  Contact Us
                </Typography>
                <Typography
                  variant="subtitle2"
                  onClick={() => navigate(appInternalRoutes.CONTACT_US_PAGE)}
                >
                  Book a consultation
                </Typography>
                <Typography
                  onClick={() => navigate(appInternalRoutes.OUR_TEAM)}
                  variant="subtitle2"
                >
                  Our Team
                </Typography>
                {/* <Typography variant="subtitle2">Be Our Partner</Typography> */}
                {/* <Typography variant="subtitle2">Blogs</Typography> */}
                {/* <Typography variant="subtitle2">Latest Updates</Typography> */}
                {/* <Typography variant="subtitle2">Privacy Policy</Typography> */}
                {/* <Typography variant="subtitle2">Terms & Condition</Typography> */}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3 overflow-hidden">
            <div className="space-y-8 text-center md:text-left">
              <Typography variant="h5">Contact Info</Typography>

              <div className="space-y-4 *:cursor-pointer">
                <span className="flex items-center space-x-2 justify-center md:justify-start">
                  <EmailOutlinedIcon />
                  <a href="mailto:info@visionstreamconsultancy.com">
                    <Typography variant="subtitle2">
                      Info@visionstreamconsultancy.com
                    </Typography>
                  </a>
                </span>

                <span className="flex items-center space-x-2 justify-center md:justify-start">
                  <LocalPhoneOutlinedIcon />
                  <a href="tel:14163338747">
                    <Typography variant="subtitle2">
                      +1 (416) 333-8747
                    </Typography>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <Typography className="text-center pt-8" variant="body1">
          Copyright ©2024 All rights reserved | Vision Stream
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
